/*
	Main
*/
* {
	--bs-body-font-family: AcherusgrotesqueRegular, serif
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
	Bootstrap colors
*/
.btn-outline-danger {
	--bs-btn-color: #00baba;
	--bs-btn-border-color: #00baba;
	--bs-btn-hover-color: #ffffff;
	--bs-btn-hover-bg: #00baba;
	--bs-btn-hover-border-color: #00baba;
	--bs-btn-active-color: #ffffff;
	--bs-btn-active-bg: #00baba;
	--bs-btn-active-border-color: #00baba;
	--bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 186, 186, 0.5);
}

/*
	Fonts
*/
@font-face {
	font-family: AcherusgrotesqueRegular;
	src: url(../../fonts/acherusgrotesque-regular.otf);
}

/*
	Navbar
*/
nav#navbartop {
	div.navbar-fill {
		width: 15%;
		height: 70px;
	}

	a.navbar-brand {
	}
}

.navbar-toggler {
	width: 30px;
	height: 30px;
	padding: 0 !important;
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
	position: relative;
	transition: 0.5s ease-in-out;
	display: flex;
	justify-content: center;

	span {
		margin: 0;
		padding: 0;

		&.toggler-icon {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: rgba(0,0,0,0.55);
			border-radius: 2px;
			left: 0;
			opacity: 1;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;

			&.middle-bar {
				margin-top: 0;
			}
		}

		&.top-bar {
			margin-top: 0;
			transform: rotate(135deg);
		}

		&.middle-bar {
			opacity: 0;
			filter: alpha(opacity=0);
		}

		&.bottom-bar {
			margin-top: 0;
			transform: rotate(-135deg);
		}
	}

	&.collapsed {
		span {
			&.toggler-icon {
				&.top-bar {
					margin-top: -20px;
					transform: rotate(0deg);
				}

				&.middle-bar {
					opacity: 1;
					filter: alpha(opacity=100);
				}

				&.bottom-bar {
					margin-top: 20px;
					transform: rotate(0deg);
				}
			}
		}
	}
}

/*
	Footer
*/
footer {
	position: relative;
	z-index: 999;

	div {
		a {
			i.footer-icon {
				text-decoration: none;
				transition: 0.5s;

				&:hover {
					color: #00baba !important;
				}
			}
		}
	}

	div.container {
		div.row {
			div {
				p {
					a.footer-text {
						text-decoration: none;
						transition: 0.5s;

						&:hover {
							color: #00baba !important;
						}
					}
				}

				div {
					margin-bottom: 16px;
				}
			}
		}
	}

	div.footer-copyright {
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 10px;

		a {
			text-decoration: none;
			transition: 0.5s;

			&:hover {
				color: #00baba !important;
			}
		}
	}
}

/*
	Index
*/
// Main
section#main {
	width: 100%;
	height: calc(100vh - 70px);
	background-size: cover;
	background: url("../../imgs/index/mainbackground.webp") no-repeat fixed;
	background-size: cover;
	position: relative;

	div.text-container {
		position: absolute;
		background-color: #f8f9fa;
		top: 30px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;

		animation: anim 1s ease-in-out;

		@keyframes anim {
			from {
				transform: translateX(-120%);
			}
			to {
				transform: translateX(0);
			}
		}

		h1 {
			margin-bottom: 0;

			@media(min-width: 800px) {
				font-size: 72px;
			}
			@media(min-width: 500px) and (max-width: 799px) {
				font-size: 48px;
			}
			@media(min-width: 400px) and (max-width: 499px) {
				font-size: 36px;
			}
			@media(min-width: 350px) and (max-width: 399px) {
				font-size: 30px;
			}
			@media(max-width: 349px) {
				font-size: 24px;
			}
		}

		h4 {
			margin-bottom: 0;

			@media(max-width: 399px) {
				font-size: 18px;
			}
		}
	}
}

// Services
section#services {
	width: 100%;
	position: relative;
	background-color: #dddddd;
	z-index: 10;

	@media(min-width: 550px) {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 70%;
			background-color: #ffffff;
			clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
			z-index: -1
		}
	}

	div.big-container {
		position: absolute;
		background-color: #c6c6c6;
		top: -50px;
		right: 0;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;

		div.big-wrapper {
			display: inline-block;

			h2 {
				margin-bottom: 0;
			}

			ul.text-container {
				padding-inline-start: 0;
				padding-inline-end: 0;

				li {
					list-style-type: none;
					position: relative;

					&::after {
						top: 0;
						position: absolute;
						color: #00baba;
					}

					&.header {
						font-size: 1.5rem;
						padding-left: 20px;

						&::after {
							left: 0;
							content: '»';
						}
					}

					&.point {
						font-size: 1.25rem;
						padding-left: 40px;

						&::after {
							left: 20px;
							content: '-';
						}
					}
				}
			}
		}
	}

	div.small-container {
		width: 100%;

		div.header-container {
			width: 100%;

			div.divider {
				width: 100px;
				height: 5px;
				background-color: #00baba;
				margin-left: auto;
			}
		}

		ul.text-container {
			padding-inline-start: 0;
			padding-inline-end: 0;

			li {
				list-style-type: none;
				position: relative;
				text-align: right;

				&::after {
					top: 0;
					position: absolute;
					color: #00baba;
				}

				&.header {
					font-size: 1.5rem;
					padding-right: 20px;

					&::after {
						right: 0;
						content: '«';
					}
				}

				&.point {
					font-size: 1.25rem;
					padding-right: 40px;

					&::after {
						right: 20px;
						content: '-';
					}
				}
			}
		}
	}
}

// About
section#about {
	width: 100%;
	position: relative;
	scroll-margin-top: 70px;
	border: 5px solid #c7c7c7;

	@media(min-width: 800px) {
		&::before {
			content: '';
			position: absolute;
			top: -2px;
			left: -2px;
			bottom: -2px;
			width: 30%;
			background-color: #c7c7c7;
			clip-path: polygon(0 0, 50% 0, 100% 100%, 0 100%);
			z-index: -1
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -2px;
			right: -2px;
			width: 200px;
			height: 200px;
			background-color: #c7c7c7;
			clip-path: polygon(100% 0, 0 100%, 100% 100%);
			z-index: -1;
		}
	}

	div.container {
		div.header-container {
			width: 100%;

			h1 {
				font-size: 72px;
				margin-bottom: 0;
			}

			div.divider {
				width: 100px;
				height: 5px;
				background-color: #00baba;

				@media(max-width: 575px) {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		div.row {
			ul {
				padding-inline-start: 0;
				padding-inline-end: 0;

				li {
					list-style-type: none;
					position: relative;
					padding-left: 20px;

					@media(min-width: 576px) {
						font-size: 18px;
					}
					@media(max-width: 575px) {
						font-size: 16px;
					}

					&::after {
						top: 0;
						position: absolute;
						color: #00baba;
						left: 0;
						content: '»';
					}
				}
			}
		}
	}
}

// Accountancy
section#accountancy {
	width: 100%;
	position: relative;
	scroll-margin-top: 70px;
	background-color: #dddddd;
	z-index: 0;
	border-bottom: 5px solid #dddddd;

	@media(min-width: 768px) {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 70%;
			background-color: #ffffff;
			clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
			z-index: -1
		}
	}

	h1 {
		margin-bottom: 0;

		@media(min-width: 601px) {
			font-size: 72px;
		}

		@media(min-width: 351px) and (max-width: 600px) {
			font-size: 48px;
		}

		@media(max-width: 350px) {
			font-size: 36px;
		}
	}

	div.header-container {
		div.divider {
			width: 100px;
			height: 5px;
			background-color: #00baba;
			margin-right: auto;
		}
	}

	ul.text-container {
		padding-inline-start: 0;
		padding-inline-end: 0;

		h4 {
			margin-bottom: 0;
		}

		li {
			list-style-type: none;
			position: relative;
			font-size: 18px;

			&::after {
				top: 0;
				position: absolute;
				color: #00baba;
			}
		}

		&.right {
			@media(max-width: 991px) {
				text-align: left;

				li {
					padding-left: 20px;

					&::after {
						content: '»';
						left: 0;
					}
				}
			}

			@media(min-width: 992px) {
				text-align: right;

				li {
					padding-right: 20px;

					&::after {
						content: '«';
						right: 0;
					}
				}
			}
		}

		&.left {
			text-align: left;

			li {
				padding-left: 20px;

				&::after {
					content: '»';
					left: 0;
				}
			}
		}
	}
}

// Other
section#other {
	width: 100%;
	position: relative;
	scroll-margin-top: 70px;
	z-index: 0;

	@media(min-width: 768px) {
		&::before {
			content: '';
			position: absolute;
			top: -2px;
			left: 0;
			bottom: -2px;
			width: 40%;
			background-color: #dddddd;
			clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
			z-index: -1
		}
	}

	div.header-container {
		@media(max-width: 991px) {
			text-align: left;
		}

		@media(min-width: 992px) {
			text-align: right;
		}

		h1 {
			margin-bottom: 0;

			@media(min-width: 800px) {
				font-size: 72px;
			}

			@media(min-width: 601px) and (max-width: 799px){
				font-size: 52px;
			}

			@media(min-width: 351px) and (max-width: 600px) {
				font-size: 36px;
			}

			@media(max-width: 350px) {
				font-size: 28px;
			}
		}

		div.divider {
			width: 100px;
			height: 5px;
			background-color: #00baba;

			@media(min-width: 992px) {
				margin-left: auto;
			}

			@media(max-width: 991px) {
				margin-right: auto;
			}
		}
	}

	ul.text-container {
		padding-inline-start: 0;
		padding-inline-end: 0;

		li {
			list-style-type: none;
			position: relative;
			font-size: 18px;

			&::after {
				top: 0;
				position: absolute;
				color: #00baba;
			}
		}

		@media(max-width: 991px) {
			text-align: left;

			li {
				padding-left: 20px;

				&::after {
					content: '»';
					left: 0;
				}
			}
		}

		@media(min-width: 992px) {
			text-align: right;

			li {
				padding-right: 20px;

				&::after {
					content: '«';
					right: 0;
				}
			}
		}
	}
}

section#pricing {
	width: 100%;
	position: relative;
	background-color: #dddddd;
	scroll-margin-top: 70px;

	div.container {
		div.row {
			div.col-md-4 {
				display: flex;
				justify-content: center;
				align-items: center;

				div.header-container {
					text-align: center;
					h1 {
						font-size: 72px;
						margin-bottom: 0;
					}

					p {
						font-size: 18px;
						margin-bottom: 0;
					}
				}
			}

			div.col-md-8 {
				div.list-container {
					width: 100%;

					div.list-element {
						width: 100%;
						background-color: #ffffff;
						border-radius: 20px;
						text-align: center;
						border: 2px solid #ffffff;
						transition: 0.5s;

						&:hover {
							border: 2px solid #00baba;
							transform: translateY(-10px);
						}

						p {

							margin-bottom: 0;

							@media(min-width: 351px) {
								font-size: 18px;
							}

							@media(min-width: 311px) and (max-width: 350px) {
								font-size: 16px;
							}

							@media(max-width: 310px) {
								font-size: 14px;
							}
						}
					}
				}
			}
		}
	}
}

section#contact {
	width: 100%;
	position: relative;
	background-color: #ffffff;
	scroll-margin-top: 70px;

	div.header-container {
		h1 {
			margin-bottom: 0;

			@media(min-width: 371px) {
				font-size: 72px;
			}
			@media(min-width: 321px) and (max-width: 370px) {
				font-size: 62px;
			}
			@media(max-width: 320px) {
				font-size: 52px;
			}
		}

		div.divider {
			width: 100px;
			height: 5px;
			background-color: #00baba;
			margin-right: auto;
			margin-left: auto;
		}
	}

	div.section-container {
		width: 100%;
		height: 100%;
		background-color: #dddddd;
		border-radius: 20px;
		display: flex;
		flex-direction: column;

		div.subheader-container {
			h1 {
				margin-bottom: 0;

				@media(min-width: 371px) {
					font-size: 52px;
				}
				@media(min-width: 321px) and (max-width: 370px) {
					font-size: 47px;
				}
				@media(max-width: 320px) {
					font-size: 42px;
				}
			}

			div.divider {
				width: 100px;
				height: 5px;
				background-color: #00baba;
				margin-right: auto;
				margin-left: auto;
			}
		}

		div.map-container {
			width: 100%;
			flex: 1;

			div.map-element-container {
				width: 100%;
				min-height: 400px;
				border-radius: 15px;
				border: #ffffff solid 5px;
				height: 100%;
			}
		}

		div.form-container {
			width: 100%;
			height: 100%;

			div.contact-alert {
				margin-bottom: 0;
				top: 65px;
				left: 5px;
				margin-right: 5px;
				z-index: 9999;
				transition: all 0.5s ease-in-out;
				transform: translateX(-650px);
				padding-right: 3rem;

				button.contact-alert-close {
					position: absolute;
					top: 0;
					right: 0;
					z-index: 2;
					padding: 1.25rem 1rem;
				}

				&.contact-alert-active {
					transform: translateX(0);
				}
			}

			form.contact-form {
				div.contact-text-container {
					input.contact-info-text-input {
						width: 100%;
						border-radius: 15px;
						border: 2px solid #00baba;
						transition: 0.5s;
						outline: none;

						&.highlight {
							background-color: rgba(255, 0, 0, 0.2) !important;
						}

						&:focus {
							border: 2px solid #c6c6c6;
						}
					}
				}

				div.contact-phone-container {
					div.contact-info-phone-container {
						div.countrycode-container {
							display: inline-block;

							@media(min-width: 1701px){
								width: 15%;
							}

							@media(min-width: 1101px) and (max-width: 1700px) {
								width: 25%;
							}

							@media(min-width: 768px) and (max-width: 1100px) {
								width: 35%;
							}

							@media(min-width: 500px) and (max-width: 767px) {
								width: 15%;
							}

							@media(min-width: 350px) and (max-width: 499px) {
								width: 25%;
							}

							@media(max-width: 349px) {
								width: 30%;
							}

							input.contact-info-countrycode-input {
								width: 100%;
								border-top-left-radius: 15px;
								border-bottom-left-radius: 15px;
								border-bottom: 2px solid #00baba;
								border-top: 2px solid #00baba;
								border-left: 2px solid #00baba;
								border-right: 1px solid #00baba;
								outline: none;
								transition: 0.5s;

								&.highlight {
									background-color: rgba(255, 0, 0, 0.2) !important;
								}

								&:focus {
									border-bottom: 2px solid #dddddd;
									border-top: 2px solid #dddddd;
									border-left: 2px solid #dddddd;
									border-right: 1px solid #dddddd;
								}
							}
						}


						div.phone-container {
							display: inline-block;

							@media(min-width: 1701px){
								width: 85%;
							}

							@media(min-width: 1101px) and (max-width: 1700px) {
								width: 75%;
							}

							@media(min-width: 768px) and (max-width: 1100px) {
								width: 65%;
							}

							@media(min-width: 500px) and (max-width: 767px) {
								width: 85%;
							}

							@media(min-width: 350px) and (max-width: 499px) {
								width: 75%;
							}

							@media(max-width: 349px) {
								width: 70%;
							}

							input.contact-info-phone-input {
								width: 100%;
								border-top-right-radius: 15px;
								border-bottom-right-radius: 15px;
								border-bottom: 2px solid #00baba;
								border-top: 2px solid #00baba;
								border-left: 1px solid #00baba;
								border-right: 2px solid #00baba;
								outline: none;
								transition: 0.5s;

								&.highlight {
									background-color: rgba(255, 0, 0, 0.2) !important;
								}

								&:focus {
									border-bottom: 2px solid #c6c6c6;
									border-top: 2px solid #c6c6c6;
									border-left: 1px solid #c6c6c6;
									border-right: 2px solid #c6c6c6;
								}
							}
						}
					}
				}

				div.contact-message-container {
					width: 100%;

					textarea.contact-info-textarea-input {
						width: 100%;
						border-top-left-radius: 15px;
						border-top-right-radius: 15px;
						border: 2px solid #00baba;
						transition: 0.5s;
						outline: none;
						resize: none;
						height: 200px;

						@media(min-width: 351px) {
							border-bottom-left-radius: 15px;
						}

						&.highlight {
							background-color: rgba(255, 0, 0, 0.2) !important;
						}

						&:focus {
							border: 2px solid #c6c6c6;
						}
					}

					div.contact-info-counter-container {
						width: 100%;
						display: flex;
						flex-direction: row-reverse;

						&.firefox-browser {
							margin-top: -3px;
						}

						&.chrome-browser {
							margin-top: -9px;
						}

						&.safari-browser {
							margin-top: -10px;
						}

						div.contact-info-counter-wrapper {
							border-right: 2px solid #00baba;
							border-left: 2px solid #00baba;
							border-bottom: 2px solid #00baba;
							z-index: 2;
							background-color: #ffffff;
							transition: 0.5s;

							@media(max-width: 350px) {
								width: 100% !important;
								border-bottom-left-radius: 15px;
								border-bottom-right-radius: 15px;
								text-align: right;
							}

							@media(min-width: 351px) {
								min-width: 80px;
								border-bottom-left-radius: 10px;
								border-bottom-right-radius: 10px;
								text-align: center;
							}

							&.focus {
								border-right: 2px solid #c6c6c6;
								border-left: 2px solid #c6c6c6;
								border-bottom: 2px solid #c6c6c6;
							}

							span {
								transition: 0.2s;

								&.red {
									color: #ff0000;
								}

								&.green {
									color: #00ff00;
								}
							}
						}
					}
				}

				div.contact-submit-container {
					width: 100%;
					display: flex;
					justify-content: center;

					span.contact-submit-loader {
						height: 30px !important;
						width: 30px !important;

						&.text {
							display: none;
						}

						&.loader {
							display: inline-block;
						}
					}

					button.contact-submit-button {
						border-radius: 15px;
						border: 2px solid #00baba;
						transition: 0.5s;
						outline: none;
						width: 100px;

						&.text {
							display: inline-block;
						}

						&.loader {
							display: none;
						}

						&:hover {
							border: 2px solid #c6c6c6;
						}
					}
				}

				div.contact-terms-container {
					text-align: center;
					width: 100%;

					label.contact-terms-label {
						user-select: none;
						-webkit-user-select: none;
						-ms-user-select: none;

						&.highlight {
							&:before {
								box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(255, 0, 0, .2) !important;
							}
						}

						a.contact-terms-a {
							color: #00baba;
							transition: 0.5s;
							text-decoration: none;

							&:hover {
								color: #008a8a;
							}
						}
					}

					input[type="checkbox"].contact-terms-checkbox {
						&:not(:checked) {
							position: absolute;
							left: 0;
							opacity: 0.01;

							+ {
								label.contact-terms-label {
									position: relative;
									padding-left: 2.3em;
									font-size: 1.05em;
									line-height: 1.7;
									cursor: pointer;

									&:before {
										content: '';
										position: absolute;
										left: 0;
										top: 0;
										width: 1.4em;
										height: 1.4em;
										border: 1px solid #aaa;
										background: #FFF;
										border-radius: .2em;
										box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(0, 186, 186, .2);
										-webkit-transition: all .275s;
										transition: all .275s;
									}

									&:after {
										content: '✕';
										position: absolute;
										top: .548em;
										left: .12em;
										font-size: 1.375em;
										color: #00baba;
										line-height: 0;
										-webkit-transition: all .2s;
										transition: all .2s;
										opacity: 0;
										-webkit-transform: scale(0) rotate(45deg);
										transform: scale(0) rotate(45deg);
									}
								}
							}
							&:focus {
								+ {
									label.contact-terms-label {
										&:before {
											box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(0, 186, 186, .2);
										}
									}
								}
							}
						}
						&:checked {
							position: absolute;
							left: 0;
							opacity: 0.01;

							+ {
								label.contact-terms-label {
									position: relative;
									padding-left: 2.3em;
									font-size: 1.05em;
									line-height: 1.7;
									cursor: pointer;

									&:before {
										content: '';
										position: absolute;
										left: 0;
										top: 0;
										width: 1.4em;
										height: 1.4em;
										border: 1px solid #aaa;
										background: #FFF;
										border-radius: .2em;
										box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 rgba(0, 186, 186, .2);
										-webkit-transition: all .275s;
										transition: all .275s;
									}

									&:after {
										content: '✕';
										position: absolute;
										top: .548em;
										left: .12em;
										font-size: 1.375em;
										color: #00baba;
										line-height: 0;
										-webkit-transition: all .2s;
										transition: all .2s;
										opacity: 1;
										-webkit-transform: scale(1) rotate(0);
										transform: scale(1) rotate(0);
									}
								}
							}
							&:focus {
								+ {
									label.contact-terms-label {
										&:before {
											box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(0, 186, 186, .2);
										}
									}
								}
							}
						}
					}
				}

				div.contact-recaptcha-container {
					display: none;
				}

				div.contact-errors-container {
					width: 100%;
					text-align: center;
					color: #ff0000;
					font-size: 18px;
					margin-top: 10px;
				}
			}
		}
	}
}

section#error {
	width: 100%;
	height: calc(100vh - 70px);

	@media(min-width: 800px) {
		&::before {
			content: '';
			position: absolute;
			top: -2px;
			left: -2px;
			bottom: -2px;
			width: 70%;
			background-color: #c7c7c7;
			clip-path: polygon(0 0, 70% 0, 40% 100%, 0% 100%);
			z-index: -1
		}
	}

	div.img-container {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 50%;
		}
	}

	div.text-container {
		display: flex;
		justify-content: center;
		align-items: center;

		div.subtext-container {
			h4 {
				a {
					color: #00baba;
					text-decoration: none;
					transition: 0.5s;

					&:hover {
						color: #008a8a;
					}
				}
			}
		}
	}

	div.button-container {
		button {
			font-size: 18px;
		}
	}
}

/*
	Terms
*/
section#terms {
	width: 100%;

	iframe {
		width: 100%;
		height: calc(100vh - 70px);
	}
}